import React, { useMemo, useRef, useState } from 'react';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-components';
import { NavLink, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { App, Button, FormInstance } from 'antd';
import {
  DeleteOutlined, EditOutlined,
} from '@ant-design/icons';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import Table, { defaultPagingResponse } from '../../../Common/Table';
import { useMessageError } from '../../../../hooks/common';
import { dateFormat } from '../../../../contstant';
import { useSimpleModal } from '../../../Common/Modal/Simple';
import {
  Certificate,
  TableCertificateRow,
  useCertificateDelete,
  useTableCertificatesGet,
} from '../../../../hooks/api/certificates';
import { createDefaultHiddenColumns, getSorterParams, queryFilterParams } from '../../../../utils';
import ReGiftModal from '../Modal';
import { UserSubscriptionEnum } from '../../../../enums/user';

interface TableCertificates {
  params?: Record<string, string>;
}

function CertificatesTable(props: TableCertificates): React.JSX.Element {
  const { params } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const formRef = useRef<FormInstance>();

  const certificatesGet = useTableCertificatesGet();
  const certificateDelete = useCertificateDelete();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedCertificate, setSelectedCertificate] = useState<Certificate>();

  const { open, contextHolder } = useSimpleModal();
  const { message } = App.useApp();
  const actionRef = useRef<ActionType>();

  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<RequestData<TableCertificateRow>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams(queryFilterParams({ ...args, ...getSorterParams(sorter) }), { replace: true });

    return certificatesGet.fetch({
      ...newParams,
      ...params,
    }).then((res) => {
      if (res) {
        const { data, total } = res;

        return ({ data: data || [], success: true, total });
      }

      return defaultPagingResponse;
    });
  };

  useMessageError([certificatesGet, certificateDelete]);

  const handleDeleteClick = (e: React.MouseEvent<HTMLElement>, certificate: Certificate) => {
    e.preventDefault();
    e.stopPropagation();

    open({
      title: 'Удалить сертификат?',
      content: 'Вы уверены, что хотите навсегда удалить этот сертификат?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Удалить',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => certificateDelete.fetch(certificate.id).then(() => {
        message.success('Сертификат успешно удален');
        actionRef.current?.reload();
      }),
      maskClosable: true,
    });
  };

  const handleReGiftClick = (e: React.MouseEvent<HTMLElement>, certificate: Certificate) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedCertificate(certificate);
    handleModal(true);
  };

  const columns: ProColumns<TableCertificateRow>[] = [
    {
      title: 'Сертификат',
      dataIndex: 'value',
      sorter: false,
      hideInSearch: true,
    },
    {
      title: 'Дата покупки',
      dataIndex: 'createdAt',
      sorter: true,
      hideInSearch: true,
      renderText: (_, row) => (row.createdAt ? dayjs(row.createdAt).format(dateFormat) : ''),
    },
    {
      title: 'Тип',
      dataIndex: 'subscription',
      sorter: false,
      hideInSearch: true,
      renderText: (text: keyof typeof UserSubscriptionEnum) => UserSubscriptionEnum[text],
    },
    {
      title: 'Покупатель',
      dataIndex: 'buyer',
      sorter: false,
      hideInSearch: true,
      // render: (_, row) => row.buyer?.email,
      render: (_, row) => (
        // <NavLink to={`/users?search=${row.buyer?.email}`}>{row.buyer?.email}</NavLink>
        <NavLink to={`/users/${row.buyer?.id}`}>{row.buyer?.email}</NavLink>
      ),
    },
    {
      title: 'Емейл покупателя',
      dataIndex: 'buyerEmail',
      sorter: false,
      hideInSearch: true,
      render: (_, row) => row?.buyerEmail || '-',
    },
    {
      title: 'Ордер',
      dataIndex: 'orderNumber',
      sorter: false,
      hideInSearch: true,
      // renderText: (text) => (text ? `№${text}` : '-'),
    },
    {
      title: 'Сумма/Метод оплаты',
      dataIndex: 'paymentMethod',
      sorter: false,
      hideInSearch: true,
      render: (_, row) => (
        <div>
          {row.paymentSum ? <p>{row.paymentSum}</p> : '-'}
          {row.paymentMethod ? <p>{row.paymentMethod}</p> : null}
        </div>
      ),
    },
    {
      title: 'Получатель', // 'Адресат',
      dataIndex: 'giftedTo',
      sorter: false,
      hideInSearch: true,
    },
    {
      title: 'Дата активации',
      dataIndex: 'activatedAt',
      sorter: true,
      hideInSearch: true,
      renderText: (activatedAt) => (activatedAt ? dayjs(activatedAt).format(dateFormat) : ''),
    },
    {
      title: 'Активатор',
      dataIndex: 'activator',
      sorter: false,
      hideInSearch: true,
      render: (_, row) => (
        // ?tab=marathon
        <NavLink to={`/users/${row.activator?.id}`}>
          {row.activator?.email}
        </NavLink>
      ),
    },
    {
      key: 'actions',
      width: 130,
      title: 'Действия',
      dataIndex: 'actions',
      sorter: false,
      hideInSearch: true,
      hideInSetting: true,
      render: (_, row) => (
        <>
          {row.activatedAt === null ? (
            <Button
              size="middle"
              type="text"
              icon={<EditOutlined style={{ fontSize: 18 }} />}
              onClick={(e) => handleReGiftClick(e, row)}
              title="ReGift"
            />
          ) : null}
          <Button
            size="middle"
            type="text"
            icon={<DeleteOutlined style={{ fontSize: 18 }} />}
            onClick={(e) => handleDeleteClick(e, row)}
            title="Удалить"
          />
        </>
      ),
    },
  ];

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams({ ...newParams, ...params }, { replace: true });

    return { ...newParams, ...params };
  };

  const handleModal = (payload: boolean) => {
    setIsModalOpen(payload);

    if (!payload) {
      setSelectedCertificate(undefined);
    }
  };

  const handleReloadTable = () => {
    actionRef.current?.reload();
  };

  const defaultHiddenColumns = useMemo(() => createDefaultHiddenColumns(
    ['createdAt'], // 'paymentMethod'
  ), []);

  return (
    <>
      {contextHolder}
      <Table<TableCertificateRow>
        className="table-hide-filters"
        withCustomSearch
        formRef={formRef}
        actionRef={actionRef}
        style={{ paddingBottom: '24px', background: '#fff', borderRadius: '6px' }}
        headerTitle="Список Сертификатов"
        columns={columns}
        request={tableRequest}
        showSorterTooltip={false}
        beforeSearchSubmit={beforeSearchSubmit}
        columnsState={{
          persistenceKey: 'pro-table-certificates',
          persistenceType: 'localStorage',
          defaultValue: defaultHiddenColumns,
        }}
      />
      {isModalOpen && selectedCertificate !== undefined ? (
        <ReGiftModal
          isOpenModal={isModalOpen}
          handleModal={handleModal}
          selectedCertificate={selectedCertificate}
          handleReloadTable={handleReloadTable}
        />
      ) : null}
    </>
  );
}

export default CertificatesTable;
