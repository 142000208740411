import React, { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import Tabs from 'antd/es/tabs';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { App, Button } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import General from './General';
import SinglePageContainer from '../../../Common/SinglePageContainer';
import UserMarathon from './Marathon';
import { useUserProfileProvider } from '../../../../context/userProfile';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import Loading from '../../../Common/Loading';
import PurchaseList from './PurchaseList';
import { useUserActivateAccount } from '../../../../hooks/api/user';
import ChangePasswordButtonModal from '../ChangePasswordModal';
import { useSimpleModal } from '../../../Common/Modal/Simple';

const breadcrumbs: ItemType[] = [
  {
    title: <NavLink to="/users">Пользователи</NavLink>,
  },
  {
    title: 'Просмотр профиля пользователя',
  },
];

function Profile() {
  const { id = '' } = useParams<{ id: string; }>();
  const { userById } = useUserProfileProvider();
  const { message } = App.useApp();
  const { open, contextHolder } = useSimpleModal();
  const [searchParams, setSearchParams] = useSearchParams();
  const userActivateAccount = useUserActivateAccount();

  const fetchId = () => {
    userById.fetch(undefined, id);
  };

  useEffect(() => {
    if (id) {
      fetchId();
    }
  }, [id]);

  const handleActivateClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    open({
      title: 'Активировать аккаунт',
      content: 'Вы уверены, что хотите активировать этот аккаунт?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Активировать',
      okType: 'primary',
      onOk: () => userActivateAccount.fetch(undefined, `${id}/activate-account`).then((res) => {
        if (res?.id) {
          message.success('Аккаунт активирован.');
          fetchId();
        }
      }),
      maskClosable: true,
    });
  };

  return (
    <SinglePageContainer
      breadcrumbItems={breadcrumbs}
      title="Просмотр профиля пользователя"
      // cardLoading={userById.loading}
      style={{ minHeight: '250px' }}
      extra={(
        <>
          <ChangePasswordButtonModal id={id} />
          {userById.data?.status === 'pending' ? (
            <Button
              size="middle"
              type="primary"
              icon={<CheckCircleOutlined style={{ fontSize: 18 }} />}
              onClick={(e) => handleActivateClick(e)}
              title="Активировать аккаунт"
              loading={userActivateAccount.loading || userById.loading}
            >
              Активировать аккаунт
            </Button>
          ) : null}
        </>
      )}
    >
      <Loading visible={userById.loading} absolute transparentBg />
      {contextHolder}

      {userById.data ? (
        <Tabs
          activeKey={searchParams.get('tab') || 'general'}
          onChange={(tab) => setSearchParams({ tab }, { replace: true })}
          items={[
            {
              key: 'general',
              label: 'Общее',
              children: <General data={userById.data} />,
            },
            {
              key: 'marathon',
              label: 'Марафон',
              children: <UserMarathon data={userById.data} />,
            },
            {
              key: 'purchaseList',
              label: 'Журнал покупок',
              children: <PurchaseList data={userById.data} />,
            },
          ]}
        />
      ) : null}
    </SinglePageContainer>
  );
}

export default Profile;
