import React, {
  Key,
  useCallback, useMemo, useRef,
} from 'react';
import { ActionType, ProColumns, RequestData } from '@ant-design/pro-components';
import {
  App, Button, FormInstance, Tooltip,
} from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SortOrder } from 'antd/es/table/interface';
import { ParamsType } from '@ant-design/pro-provider';
import dayjs from 'dayjs';
import {
  CheckCircleOutlined,
} from '@ant-design/icons';
import Table, { defaultPagingResponse } from '../../../Common/Table';
import {
  TableUserRow, User, useTableUsersGet, useUserActivateAccount,
} from '../../../../hooks/api/user';
import {
  createDefaultHiddenColumns, getSorterParams, queryFilterParams,
} from '../../../../utils';
import StatusIndicator from '../../../Common/StatusIndicator';
import { useMessageError } from '../../../../hooks/common';
import SelectStatus from '../../../Common/Selects/SelectStatus';
import SelectSubscription from '../../../Common/Selects/SelectSubscription';
import { SubscriptionEnum } from '../../../../enums';
import { dateFormat } from '../../../../contstant';
import { useSimpleModal } from '../../../Common/Modal/Simple';

interface TableUsers {
  params?: Record<string, string>;
  selectedRows?: number[];
  onRowSelection?: ((selectedRows: number[]) => void) | undefined;
}

function UsersTable(props: TableUsers): React.JSX.Element {
  const { params, selectedRows, onRowSelection } = props;
  const { message } = App.useApp();
  const { open, contextHolder } = useSimpleModal();
  const userActivateAccount = useUserActivateAccount();

  const navigate = useNavigate();
  const usersGet = useTableUsersGet();
  const formRef = useRef<FormInstance>();
  const actionRef = useRef<ActionType>();

  const [searchParams, setSearchParams] = useSearchParams();

  const handleActivateClick = (e: React.MouseEvent<HTMLElement>, user: User) => {
    e.preventDefault();
    e.stopPropagation();

    open({
      title: 'Активировать аккаунт',
      content: 'Вы уверены, что хотите активировать этот аккаунт?',
      cancelText: 'Отмена',
      centered: true,
      okText: 'Активировать',
      okType: 'primary',
      onOk: () => userActivateAccount.fetch(undefined, `${user.id}/activate-account`).then((res) => {
        if (res?.id) {
          message.success('Аккаунт активирован.');
          actionRef.current?.reload();
        }
      }),
      maskClosable: true,
    });
  };

  /** Table request: */
  const tableRequest = async (
    { current, pageSize, ...args }: Record<string, string>
      & { pageSize?: number | undefined; current?: number | undefined; keyword?: string | undefined; },
    sorter: Record<string, SortOrder>,
  ): Promise<RequestData<TableUserRow>> => {
    const newParams = queryFilterParams({
      page: current ? `${current}` : '1',
      pageSize: pageSize ? `${pageSize}` : '10',
      ...args,
      ...getSorterParams(sorter),
    });

    setSearchParams(queryFilterParams({ ...args, ...getSorterParams(sorter) }), { replace: true });

    return usersGet.fetch({
      ...newParams,
      ...params,
    }).then((data) => {
      if (data) {
        const { users, total } = data;

        return ({ data: users || [], success: true, total });
      }

      return defaultPagingResponse;
    });
  };

  const beforeSearchSubmit = (beforeSubmitParams: Partial<ParamsType>) => {
    const newParams = queryFilterParams({
      ...beforeSubmitParams,
      _timestamp: '',
      search: searchParams.get('search') || '',
    });

    setSearchParams(newParams, { replace: true });

    return { ...newParams, ...params };
  };

  useMessageError([usersGet, userActivateAccount]);

  const columns: ProColumns<TableUserRow>[] = [
    {
      title: 'Имя',
      dataIndex: 'name',
      sorter: true,
      hideInSearch: true,
    },
    {
      title: 'Эл. почта',
      dataIndex: 'email',
      sorter: true,
      hideInSearch: true,
      ellipsis: true,
    },
    {
      title: 'Подписка',
      dataIndex: 'subscription',
      sorter: true,
      renderFormItem: (_, { defaultRender, ...config }) => <SelectSubscription {...config} />,
      valueEnum: SubscriptionEnum,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      sorter: true,
      renderFormItem: (_, { defaultRender, ...config }) => <SelectStatus {...config} />,
      renderText: (status) => <StatusIndicator status={status} />,
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      sorter: true,
      renderText: (createdAt) => (createdAt ? dayjs(createdAt).format(dateFormat) : ''),
      hideInSearch: true,
    },
    {
      title: 'Дата изменения',
      dataIndex: 'updatedAt',
      sorter: true,
      renderText: (updatedAt) => (updatedAt ? dayjs(updatedAt).format(dateFormat) : ''),
      hideInSearch: true,
    },
    {
      key: 'actions',
      width: 48,
      title: ' ',
      dataIndex: 'actions',
      sorter: false,
      hideInSearch: true,
      hideInSetting: true,
      render: (_, row) => (
        row.status === 'pending' ? (
          <Tooltip title="Активировать аккаунт">
            <Button
              size="middle"
              type="text"
              icon={<CheckCircleOutlined style={{ fontSize: 18 }} />}
              onClick={(e) => handleActivateClick(e, row)}
              title="Активировать аккаунт"
              disabled={userActivateAccount.loading}
            />
          </Tooltip>
        ) : null
      ),
    },
  ];

  const defaultHiddenColumns = useMemo(() => createDefaultHiddenColumns(['createdAt', 'updatedAt']), []);

  const onRowChange = useCallback((selectedRowKeys: Key[]) => {
    if (onRowSelection) {
      onRowSelection(selectedRowKeys as number[]);
    }
  }, [onRowSelection]);

  const rowSelection = {
    onChange: onRowChange,
    selectedRowKeys: selectedRows,
    alwaysShowAlert: false,
    preserveSelectedRowKeys: true,
  };

  return (
    <>
      {contextHolder}
      <Table<TableUserRow>
        withCustomSearch
        formRef={formRef}
        actionRef={actionRef}
        headerTitle="Список пользователей"
        columns={columns}
        request={tableRequest}
        beforeSearchSubmit={beforeSearchSubmit}
        showSorterTooltip={false}
        columnsState={{
          persistenceKey: 'pro-table-users',
          persistenceType: 'localStorage',
          defaultValue: defaultHiddenColumns,
        }}
        rowSelection={!!onRowSelection && rowSelection}
        rowClassName="cursor-pointer"
        onRow={(record) => ({
          onClick: () => navigate(`/users/${record.id}`),
        })}
      />
    </>
  );
}

UsersTable.defaultProps = {
  params: {},
  selectedRows: [],
  onRowSelection: undefined,
};

export default UsersTable;
