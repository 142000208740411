import React, { useState } from 'react';
import {
  App, Button,
  Form, Input, Modal, Typography,
} from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { createRulesForAntd, validationChangePassword } from '../../../../utils/validations';
import { useUserSetNewPassword } from '../../../../hooks/api/user';
import { useMessageError } from '../../../../hooks/common';

interface ChangePasswordModalProps {
  id: string;
  label?: React.ReactNode;
}

interface ChangePasswordForm {
  password: string;
  confirmPassword: string;
}

const validationRules = createRulesForAntd(validationChangePassword);

function ChangePasswordButtonModal({
  id,
  label = 'Изменить пароль',
}: ChangePasswordModalProps) {
  const { message } = App.useApp();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [form] = Form.useForm<ChangePasswordForm>();
  const userSetNewPassword = useUserSetNewPassword(); // set-new-password

  const onFinish = async () => {
    const values = form.getFieldsValue();

    await userSetNewPassword.fetch({ password: values.password }, `${id}/set-new-password`)
      .then((res) => {
        if (res?.id) {
          message.success('Изменения успешно сохранены');
          setIsModalOpen(false);
        }
      });
  };

  useMessageError([userSetNewPassword]);

  return (
    <>
      <Button icon={<LockOutlined />} onClick={() => setIsModalOpen(true)}>{label}</Button>
      <Modal
        open={isModalOpen}
        title="Изменить пароль"
        cancelText="Отмена"
        centered
        okText="Сохранить"
        okButtonProps={{ type: 'primary', loading: userSetNewPassword.loading }}
        cancelButtonProps={{ disabled: userSetNewPassword.loading }}
        width={400}
        onOk={() => form.submit()}
        onCancel={() => setIsModalOpen(false)}
        maskClosable={!userSetNewPassword.loading}
        destroyOnClose
      >
        <Form<ChangePasswordForm>
          form={form}
          layout="vertical"
          onFinish={onFinish}
        >
          <div style={{ marginBottom: '24px' }}>
            <Typography.Paragraph>
              Пароль должен быть не менее 12 символов и состоять из букв, цифр и специальных символов.
            </Typography.Paragraph>
          </div>

          <Form.Item name="password" label="Новый пароль" rules={[validationRules]}>
            <Input.Password placeholder="Введить пароль" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Повторите пароль"
            dependencies={['password']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Пароли не совпадают.'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Введить пароль" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ChangePasswordButtonModal;
