import React from 'react';
import {
  Col, Form, Input, Row, Select, Switch,
} from 'antd';
import SelectContentStatus from '../../../../Common/Selects/SelectStatus/SelectContentStatus';
import SelectEquipmentMulti from '../../../../Common/Selects/SelectEquipmentMulti';
import SelectTargetMulti from '../../../../Common/Selects/SelectTargetMulti';
import { enumToOptionsArray } from '../../../../../utils';
import { LevelEnum, PlaceEnum, TrainingCategoryEnum } from '../../../../../enums/trainings';
import { AvailabilityEnum } from '../../../../../enums';
import QuillRichTextEditor from '../../../../Common/QuillRichTextEditor';
import { createRulesForAntd, validationTrainingHouse } from '../../../../../utils/validations';

const validationRules = createRulesForAntd(validationTrainingHouse);

const defaultSelectProps = { placeholder: 'Выберите', allowClear: true };

function FormTrainingHome() {
  return (
    <Row gutter={24}>
      {/** Input with data which user should not see, but it should be saved */}
      <Form.Item name="thumbnail" className="hidden-form-item">
        <Input type="hidden" disabled />
      </Form.Item>

      <Col span={12}>
        <Form.Item name="nameRU" label="Название (RU)" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="nameUA" label="Название (UA)" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="availability" label="Доступность" rules={[{ required: true, ...validationRules }]}>
          <Select options={enumToOptionsArray(AvailabilityEnum)} {...defaultSelectProps} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="contentState" label="Статус">
          <SelectContentStatus />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="forCatalog" label="Отображение в каталоге" valuePropName="checked">
          <Switch checkedChildren="Да" unCheckedChildren="Нет" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="level" label="Уровень" rules={[{ required: true, ...validationRules }]}>
          <Select options={enumToOptionsArray(LevelEnum)} {...defaultSelectProps} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="place" label="Место" rules={[{ required: true, ...validationRules }]}>
          <Select options={enumToOptionsArray(PlaceEnum)} {...defaultSelectProps} disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="category" label="Категория">
          <Select options={enumToOptionsArray(TrainingCategoryEnum)} {...defaultSelectProps} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="target" label="Целевая область">
          <SelectTargetMulti />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="duration" label="Продолжительность, минут" rules={[{ required: true, ...validationRules }]}>
          <Input placeholder="Введите" disabled />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="equipment" label="Инвентарь">
          <SelectEquipmentMulti />
        </Form.Item>
      </Col>
      <Col span={12} />

      <Col span={12}>
        <Form.Item name="descriptionRU" label="Описание (RU)">
          <QuillRichTextEditor />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="descriptionUA" label="Описание (UA)">
          <QuillRichTextEditor />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item name="descriptionPremiumRU" label="Премиум Описание (RU)">
          <QuillRichTextEditor />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="descriptionPremiumUA" label="Премиум Описание (UA)">
          <QuillRichTextEditor />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default FormTrainingHome;
