import React, { useEffect, useState } from 'react';
import {
  App, Descriptions,
} from 'antd';
import dayjs from 'dayjs';
import { UserExtended } from '../../../../../hooks/api/user';
import { GenderEnum, SubscriptionEnum } from '../../../../../enums';
import { LanguageMapEnum } from '../../../../../enums/language';
import EditSubscribe from './EditSubscribe';
import StatusIndicator from '../../../../Common/StatusIndicator';

function General({ data }: { data: UserExtended }) {
  const [state, setState] = useState<UserExtended>(data);
  const [label, setLabel] = useState<string>('');

  useEffect(() => {
    setState(data);
    setLabel(handleLabel(data.subscription));
  }, [data]);

  const handleLabel = (payload: string) => {
    let subscriptionLabel = 'Нет подписки';

    if (payload === 'year') {
      subscriptionLabel = 'Годовая подписка активна до';
    } else if (payload === 'month') {
      subscriptionLabel = 'Месячная подписка активна до';
    }

    return subscriptionLabel;
  };

  const handleState = (payload: UserExtended) => {
    setState(payload);
    setLabel(handleLabel(payload.subscription));
  };

  return (
    <Descriptions
      size="small"
      column={2}
      items={[
        {
          key: '1',
          label: 'Имя',
          children: state.name,
        },
        {
          key: '2',
          label: 'Пол',
          children: state?.gender ? GenderEnum[state.gender] : '',
        },
        {
          key: '3',
          label: 'Электронная почта',
          children: state.email,
        },
        {
          key: '4',
          label: 'Дата рождения',
          children: state.dateOfBirth
            ? `${dayjs(state.dateOfBirth).format('D MMMM YYYY')} (${dayjs().diff(state.dateOfBirth, 'year')})`
            : '',
        },
        {
          key: '5',
          label: 'Подписка',
          children: SubscriptionEnum[state.subscription],
        },
        {
          key: '6',
          label: 'Рост',
          children: state.height,
        },
        {
          key: '7',
          label: 'Статус',
          children: <StatusIndicator status={state.status} />, // UserStatusEnum[state.status]
        },
        {
          key: '8',
          label: 'Вес',
          children: (state.weight2 || state.weight) ? `${state.weight2 || state.weight} кг` : '',
        },
        {
          key: '9',
          label: 'Язык',
          children: LanguageMapEnum[data.language],
        },
        {
          key: '10',
          label,
          children: <EditSubscribe data={state} handleState={handleState} />,
        },
      ]}
    />
  );
}

export default General;
