import {
  DefaultFetchError, FetchCreate,
  FetchGet,
  FetchSuccess, PagingDataResponse, PagingParams, useFetchCreate,
  useFetchDelete,
  useFetchGet,
} from '../fetch';
import { Marathon } from './marathons';

export interface Certificate {
  id: string;
  createdAt: string;
  updatedAt: string;
  value: string;
  subscription: 'gold' | 'silver' | string;
  buyer?: Buyer;
  giftedTo?: string;
  activatedAt?: string;
  activator?: Activator;

  marathon?: Marathon;
  buyerEmail?: null;
  orderNumber?: null;
  paymentSum?: null;
  paymentMethod?: null;
}

export interface Buyer {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  name: string;
  email: string;
  role: string;
}

export interface Activator {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  name: string;
  email: string;
  role: string;
}

export interface CertificateReGiftParams {
  email: string;
}

export interface CertificatesGetParams extends PagingParams {
  search?: string; // search by name & email
}

export interface TableCertificateRow extends Certificate {
  key: string;
}

export interface CertificatesTableData {
  data: TableCertificateRow[];
  total: number;
}

export const useTableCertificatesGet = (): FetchGet<
  PagingDataResponse<Certificate>, CertificatesGetParams, DefaultFetchError, CertificatesTableData> => (
  useCertificatesGet((data: PagingDataResponse<Certificate>): CertificatesTableData => ({
    data: data.data.map((certificate: Certificate): TableCertificateRow => ({
      key: certificate.id,
      ...certificate,
    })),
    total: data.meta.totalItems,
  }))
);

export function useCertificatesGet<D = PagingDataResponse<Certificate>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, CertificatesGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, CertificatesGetParams, DD>(
    'certificates',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export const useCertificateDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('certificates');

export const useCertificateReGift = (): FetchCreate<Certificate, DefaultFetchError, CertificateReGiftParams> => (
  useFetchCreate('certificates')
);
