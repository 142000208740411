import {
  DefaultFetchError,
  FetchGet,
  FetchGetId,
  PagingDataResponse,
  PagingParams,
  useFetchGet, useFetchGetId, useFetchDelete, FetchCreate, useFetchCreate, FetchUpdate, useFetchUpdate,
} from '../fetch';
import {
  AvailabilityType,
  ContentStateType, DefaultOrderByColumnProps, DurationType,
  EquipmentType,
  LevelType,
  PlaceType,
  TargetType,
} from '../../types';
import { RuLangType, UaLangType } from '../../enums/language';

export type TrainingCategoryType = 'general' | 'warm-up' | 'cool-down' | 'stretching' | 'cardio' | 'dance' | 'strength';

export interface Training {
  id: string;
  descriptionUA: string;
  descriptionRU: string;
  descriptionPremiumUA: string;
  descriptionPremiumRU: string;
  availability: AvailabilityType;
  contentState: ContentStateType;
  place: PlaceType;
  level: LevelType;
  category: TrainingCategoryType;
  equipment: EquipmentType[];
  target: TargetType[];
  forCatalog: boolean;
  createdAt: string;
  updatedAt: string;
  /** Video props */
  nameUA: string;
  nameRU: string;
  thumbnail: string;
  url: string;
  duration: number;
  /** Gym training prop: */
  exercises: Exercise[];
  usedIn?: TrainingUsedIn;
}

export interface TrainingUsedIn {
  complexes: { id: string, nameRU: string }[],
  inProgram: boolean;
  marathons: { id: string, startDate: string }[];
}

export interface Exercise {
  id?: string;
  urls: string[];
  nameUA: string;
  nameRU: string;
  descriptionUA: string;
  descriptionRU: string;
  weightUA: string;
  weightRU: string;
  numberOfSets?: number; // Deprecated
  numberOfSetsStr: string;
  numberOfRepetitions: number;
}

export interface TrainingsGetParams extends PagingParams {
  search?: string;
  lang?: UaLangType | RuLangType;
  availability?: AvailabilityType;
  contentState?: ContentStateType; // status
  place?: PlaceType;
  level?: LevelType;
  category?: TrainingCategoryType;
  duration?: DurationType;
  forCatalog?: string;
  equipment?: string[]; // EquipmentType[]
  target?: string[]; // TargetType[];
  orderByColumn?: DefaultOrderByColumnProps | 'nameRU' | 'level' | 'availability' | 'contentState' | 'place'
    | 'category' | 'target' | 'duration' | 'equipment';
}

export function useTrainingsGet<D = PagingDataResponse<Training>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, TrainingsGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, TrainingsGetParams, DD>(
    'trainings',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export const useTrainingId = (id?: string): FetchGetId<Training> => useFetchGetId(
  'trainings',
  id,
  { autoStart: false, startStateLoading: false },
);

interface VideoThumbnail {
  thumbnail: string;
  duration: number;
}
interface GetVideoThumbParams {
  url: string;
}
export const useVideoThumbnail = (): FetchGet<VideoThumbnail, GetVideoThumbParams> => useFetchGetId(
  'videos/thumbnail',
  undefined,
  { autoStart: false, startStateLoading: false },
);

export const useTrainingDelete = () => useFetchDelete<undefined, DefaultFetchError, string>('trainings');

export interface CreateTrainingPayload {
  nameUA: string;
  nameRU: string;
  url?: string;
  thumbnail: string;
  duration: number;
  /** Video props /\ */
  descriptionUA?: string;
  descriptionRU?: string;
  availability: AvailabilityType;
  contentState?: ContentStateType;
  forCatalog: boolean;
  place: PlaceType;
  level: LevelType;
  category?: TrainingCategoryType;
  equipment?: EquipmentType[];
  target?: TargetType[];
  exercises?: Exercise[];
}

export interface UpdateTrainingHousePayload extends CreateTrainingPayload {
  id?: string;
}

export const useTrainingCreate = (): FetchCreate<Training, DefaultFetchError, CreateTrainingPayload> => (
  useFetchCreate('trainings')
);

export const useTrainingUpdate = (id?: string): FetchUpdate<
  Training, DefaultFetchError, UpdateTrainingHousePayload
> => (
  useFetchUpdate('trainings', id)
);
