import { Dayjs } from 'dayjs';
import { SubscriptionEnum, UserRoles } from '../../enums/user';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet,
  FetchGetId,
  FetchSuccess,
  FetchUpdate,
  PagingDataResponse,
  PagingParams,
  useFetchCreate,
  useFetchDelete,
  useFetchGet,
  useFetchGetId,
  useFetchUpdate,
} from '../fetch';
import { pagingParamList } from '../../contstant';
import {
  DefaultOrderByColumnProps,
  IdOnly, LevelType, MenuType, ParticipantSubscriptionType, PlaceType,
} from '../../types';
import { EnLangType, RuLangType, UaLangType } from '../../enums/language';

export interface UserSettings {
  isEmailVerified: boolean
  isPhoneVerified: true
}

export type UserStatus = 'pending' | 'active' | 'inactive'; // Pending for admin-user
export type UserSubscription = 'none' | 'month' | 'year';
export type UserRole = 'user' | 'admin' | 'root';

export interface UserSimple extends IdOnly {
  id: string;
  name: string;
  email: string;
}

export interface User extends UserSimple {
  status: UserStatus;
  role: UserRole;
  subscription: SubscriptionEnum;
  createdAt: string;
  updatedAt: string;
}

export interface IUserPayments {
  createdAt: string,
  data: any,
}

export interface IUserSubscription {
  subscriptionTill?: Dayjs,
  subscription: SubscriptionEnum,
}

export interface UserExtended extends User {
  photoUrl: string;
  dateOfBirth: string;
  height?: number;
  weight?: number;
  weight2?: number; // Preferred weight. Its from version 2, and its float.
  lastPaymentAt?: string;
  lastActivityAt?: string;
  gender?: 'male' | 'female';
  language: UaLangType | RuLangType | EnLangType; // 'ua' | 'ru' | 'en'
  isEmailsAllowed: boolean;
  isMarathonPushAllowed: boolean;
  isTrainingPushAllowed: boolean;
  isMeasurementPushAllowed: boolean;
  isNewsPushAllowed: boolean;
  subscriptionTill: SubscriptionEnum,
  payments: IUserPayments[];
  participants: Participant[]; // Марафоны в которых пользователь выступал как participant
}

export interface Participant {
  id: string;
  dateStart: string;
  dateEnd: string;
  place: PlaceType;
  level: LevelType;
  isBreastFeeding: boolean;
  menuType: MenuType;
  calories: number,
  subscription: ParticipantSubscriptionType;
  contestEnteredAt: string;
  measureBefore?: Measure;
  measureAfter?: Measure;
  createdAt: string;
  updatedAt: string;
}

export interface Measure {
  date: string;
  weight: number;
  weight2?: number; // Preferred weight. Its from version 2, and its float.
  chest: number;
  hip: number;
  waist: number;
  imageUrl1: string;
  imageUrl2: string;
  imageUrl3: string;
  imageUrl4: string;
  videoUrl: string;
}

export interface UsersGetParams extends PagingParams {
  search?: string; // search by name & email
  status?: UserStatus;
  subscription?: UserSubscription;
  orderByColumn?: DefaultOrderByColumnProps | 'name' | 'email' | 'role' | 'status';
}

export interface TableUserRow extends User {
  key: string;
}

interface UsersTableData {
  users: TableUserRow[];
  total: number;
}

export const useTableUsersGet = (): FetchGet<
PagingDataResponse<User>, UsersGetParams, DefaultFetchError, UsersTableData> => (
  useUsersGet((data: PagingDataResponse<User>): UsersTableData => ({
    users: data.data.map((user: User): TableUserRow => ({
      key: user.id,
      ...user,
    })),
    total: data.meta.totalItems,
  }))
);

export function useUsersGet<D = PagingDataResponse<User>,
  DD = D>(decorateData?: (data: D) => DD): FetchGet<D, UsersGetParams, DefaultFetchError, DD> {
  return useFetchGet<D, DefaultFetchError, UsersGetParams, DD>(
    'users',
    {
      decorateData,
      autoStart: false,
      startStateLoading: false,
    },
  );
}

export const useUserId = (id?: string): FetchGetId<UserExtended> => useFetchGetId(
  'users',
  id,
  { autoStart: false, startStateLoading: false },
);

/** Not used props. Example \/ */
export const usersGetParamList = [
  'role',
  'roles',
  'locations',
  'take',
  'email',
  'status',
  'statuses',
  'orderBy',
  'fullName',
  'orderByColumn',
  'partialName',
  'ids',
  'isActive',
  'location',
  'type',
  ...pagingParamList,
];

export interface CreateUserPayload {
  id?: string;
  lastName: string;
  firstName: string;
  role?: UserRoles;
  email: string;
  password?: string;
  status?: UserStatus;
  phone: string;
  settings?: UserSettings;
}

export interface UpdateUserPayload extends CreateUserPayload {
  id?: string;
}

export interface UserCreateParams {
  phone?: string;
  status?: string;
  role?: UserRoles;
  login?: string;
  email?: string;
  password?: string;
  lastName?: string;
  firstName?: string;
}

export const useUserCreate = (): FetchCreate<FetchSuccess, DefaultFetchError, UserCreateParams> => (
  useFetchCreate('users')
);

interface AddressGetParams {
  address: string
}

export const useAddressGet = (): FetchGet<
  string[],
  AddressGetParams,
  DefaultFetchError,
  string[]
> => useFetchGet('users/address', {
  autoStart: false,
  startStateLoading: false,
  authorization: false,
});

export interface UserUpdateParams {
  role?: UserRoles;
  login?: string;
  email?: string;
  status?: string;
  password?: string;
  lastName?: string;
  firstName?: string;
  receiveEmails?: boolean
}

export interface UserUpdateSubscribeParams {
  subscriptionTill?: Dayjs,
  subscription: SubscriptionEnum,
}

export interface UserChangePassword {
  oldPassword: string,
  newPassword: string
}

export const useUserUpdate = (id?: string): FetchUpdate<FetchSuccess, DefaultFetchError, UserUpdateParams> => (
  useFetchUpdate('users', id)
);

export const useUserSubscribeUpdate = (id?: string):
  FetchUpdate<UserExtended, DefaultFetchError, Partial<UserUpdateSubscribeParams>> => (
  useFetchUpdate('users', id)
);

export const useUserDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('users');

export const useUserChangePassword = (): FetchCreate<FetchSuccess, DefaultFetchError, UserChangePassword> => (
  useFetchCreate('users/change-password')
);

export interface UserMarathonMeasurementsUpdate {
  participantId: string;
  measureBefore?: Partial<Measure>;
  measureAfter?: Partial<Measure>;
}

export const useUserMarathonMeasurementsUpdate = (id?: string): FetchUpdate<
  UserExtended, DefaultFetchError, UserMarathonMeasurementsUpdate> => (
  useFetchUpdate('users', id) // /users/{id}/as-participant
);

export interface UserMarathonUpdate {
  place: PlaceType
  level: LevelType
  menuType: MenuType
  calories: number
}

export const useUserMarathonUpdate = (id?: string): FetchUpdate<
  UserExtended, DefaultFetchError, UserMarathonUpdate> => (
  useFetchUpdate('users', id)
);

export const useUserMarathonDelete = () => useFetchDelete<FetchSuccess, DefaultFetchError, string>('users');

/** Features December 2024 */
export const useUserActivateAccount = (id?: string): FetchUpdate<
  UserExtended, DefaultFetchError, undefined> => (
  useFetchUpdate('users', id) // /users/{id}/activate-account
);

export const useUserSetNewPassword = (id?: string): FetchUpdate<
  UserExtended, DefaultFetchError, { password: string }> => (
  useFetchUpdate('users', id) // /users/{id}/set-new-password
);
